<template>
  <div class="app-container">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item   prop="goodsTitle">
        <el-input v-model="dataForm.goodsTitle" placeholder="请输入商品标题" clearable></el-input>
      </el-form-item>
      <el-form-item   prop="mallGoodsCategoryId">
        <el-select v-model="mallGoodsCategoryId" placeholder="请选择分类">
          <el-option v-for="item in categoryList" :key="item.categoryName" :label="item.categoryName" :value="item.mallGoodsCategoryId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item   prop="isShelf">
        <el-select v-model="isShelf" placeholder="请选择商品状态">
          <el-option v-for="item in shelfList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()">查询</el-button>
        <el-button @click="reset('dataForm')" >清空</el-button>
        <el-button type="danger" @click="addRecomHandle()" :disabled="dataListSelections.length <= 0">批量添加推荐</el-button>
      </el-form-item>
    </el-form>
    <!--展示数据-->
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width:100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="goodsTitle"
        header-align="center"
        align="center"
        label="标题">
      </el-table-column>
      <el-table-column
        prop="categoryName"
        header-align="center"
        align="center"
        width="180"
        label="分类名称">
      </el-table-column>

     <!-- <el-table-column
        prop="mallGoodsCategoryId"
        header-align="center"
        align="center"
        width="180"
        label="分类ID">
      </el-table-column>-->

      <el-table-column
        prop="shelfName"
        header-align="center"
        align="center"
        width="180"
        label="状态">
      </el-table-column>
      <el-table-column
        prop="updateTime"
        header-align="center"
        align="center"
        width="180"
        label="创建时间">
      </el-table-column>
      <!--操作列表-->
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="180"
        label="操作">
        <template slot-scope="scope">
          <el-button  type="text" size="small" @click="addRecomHandle(scope.row.mallGoodsMainId)">添加推荐</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
  import {addMallGoodsRecomValue,mallGoodsNoRecomValue} from "@/api/sys";
  export default {
    data() {
      return {
        dataForm: {
          goodsTitle: ""
        },
        dataList: [],
        shelfList: [{
          value: 1,
          label: "上架中"
        },
          {
            value: 0,
            label: "下架中"
          }
        ],
        isShelf:"",
        categoryList:[],
        mallGoodsCategoryId: "",
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: []
      };
    },
    components: {

    },
    methods: {
      // 清空
      reset(dataForm) {
        this.dataForm.goodsTitle = "";
        this.isShelf = "";
        this.mallGoodsCategoryId = "";
        //清空之后执行查询
        this.restPage();
      },
      // 跳到第一页进行查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      // 获取数据列表查询
      async getDataList() {
        this.dataListLoading = true;
        let params = {
          page: this.pageIndex,
          limit: this.pageSize,
          goodsTitle: this.dataForm.goodsTitle,
          isShelf: this.isShelf,
          mallGoodsCategoryId: this.mallGoodsCategoryId

        };
        const { data } = await mallGoodsNoRecomValue(params);
        if (data && data.code === 0) {
          this.dataList = data.result.page.list;
          this.totalPage = data.result.page.totalCount;
          this.categoryList = data.result.categoryList;
        } else {
          this.dataList = [];
          this.totalPage = 0;
          this.categoryList = [];
        }
        this.dataListLoading = false;
      },
      // 每页数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.pageIndex = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.pageIndex = val;
        this.getDataList();
      },
      // 多选
      selectionChangeHandle(val) {
        this.dataListSelections = val;
      },
      //添加推荐
      addRecomHandle(id) {
        var ids = id
          ? [id]
          : this.dataListSelections.map(item => {
            return item.mallGoodsMainId;
          });
        this.$confirm(`确定进行[${id ? "添加推荐" : "批量添加推荐"}]操作?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(async () => {
            // let params = {
            //   roleIds: ids
            // };
            const { data } = await addMallGoodsRecomValue(ids);
            if (data && data.code === 0) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.getDataList();
                }
              });
            } else {
              this.$message.error(data.msg);
            }
          })
          .catch(() => {});
      },

    },mounted(){
      this.getDataList();
      /*this.getAttrDataList();
      this.init();*/

    }
  };
</script>


